import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';
import Button from 'components/Button';

import { CampaignContentBottomProps } from './models';

import './CampaignContentBottom.scss';

const CampaignContentBottom: FC<CampaignContentBottomProps> = ({
  topWave,
  image,
  title,
  description,
  items,
  button,
  bottomWave,
  disclaimer,
}) => (
  <div className="campaignContentBottom">
    {topWave ? (
      <div className="campaignContentBottom__topWave">
        <Image {...topWave.imageStructure} />
      </div>
    ) : null}
    <div className="campaignContentBottom__content">
      <div className="content__text">
        <DangerouslySetInnerHtml className="content__title" html={title} />
        <DangerouslySetInnerHtml className="content__description" html={description} />
      </div>
      {image ? (
        <div className="content__image">
          <Image {...image.imageStructure} />
        </div>
      ) : null}
    </div>
    {items ? (
      <div className="campaignContentBottom__items">
        {items.map((item) => (
          <div className="item">
            <Image {...item.icon.imageStructure} className="item__image" />
            <DangerouslySetInnerHtml className="item__title" html={item.title} />
          </div>
        ))}
      </div>
    ) : null}

    <div className="campaignContentBottom__bottomWave">
      {bottomWave ? (
        <div className="bottomWave__image">
          <Image {...bottomWave.imageStructure} />
        </div>
      ) : null}

      {button?.length ? (
        <div className="bottomWave__button">
          <Button {...button[0]}>{button[0]?.label}</Button>
        </div>
      ) : null}
      {disclaimer ? (
        <DangerouslySetInnerHtml className="bottomWave__disclaimer" html={disclaimer} />
      ) : null}
    </div>
  </div>
);

export default CampaignContentBottom;
