import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { CampaignContentTopProps } from './models';

import './CampaignContentTop.scss';

const CampaignContentTop: FC<CampaignContentTopProps> = ({
  topWave,
  image,
  title,
  topTitle,
  description,
  contentItems,
}) => (
  <div className="campaignContentTop">
    {topWave ? (
      <div className="campaignContentTop__wave">
        <div className="wave__image">
          <Image {...topWave.imageStructure} />
        </div>

        <div className="wave__title">{topTitle}</div>
      </div>
    ) : null}
    <div className="campaignContentTop__content">
      <DangerouslySetInnerHtml className="content__title-top" html={title} />
      <Image {...image.imageStructure} className="content__image" />
      <div className="content__text">
        <DangerouslySetInnerHtml className="content__title" html={title} />
        <DangerouslySetInnerHtml className="content__description" html={description} />
      </div>
    </div>
    <div className="campaignContentTop__items">
      {contentItems.map((item) => (
        <div className="item">
          <Image {...item.icon.imageStructure} className="item__image" />
          <div className="item__title">{item.title}</div>
        </div>
      ))}
    </div>
  </div>
);

export default CampaignContentTop;
