import React, { FC } from 'react';

import Button from 'components/Button';
import YoutubeVideo from 'components/YoutubeVideo/YoutubeVideo';

import { CampaignVideoProps } from './models';

import './CampaignVideo.scss';

const CampaignVideo: FC<CampaignVideoProps> = ({ video, button }) => (
  <div className="campaignVideo" id="campaignVideo-id">
    {video ? (
      <div className="campaignVideo__video">
        <YoutubeVideo video={video[0]} />
      </div>
    ) : null}
    {button ? (
      <div className="campaignVideo__button">
        <Button {...button[0]} key={button.ariaLabel} className="campaignVideo__button">
          {button[0]?.label}
        </Button>
      </div>
    ) : null}
  </div>
);

export default CampaignVideo;
