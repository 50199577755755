import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import CampaignBanner from 'components/CampaignBanner/CampaignBanner';
import CampaignContentBottom from 'components/CampaignContentBottom/CampaignContentBottom';
import CampaignContentTop from 'components/CampaignContentTop/CampaignContentTop';
import CampaignItems from 'components/CampaignItems/CampaignItems';
import CampaignVideo from 'components/CampaignVideo/CampaignVideo';

import './CampaignPage.scss';

const CampaignPage: FC<CampaignPageTypes.CampaignPageProps> = ({
  data: {
    campaignPage: {
      campaignBanner,
      campaignItems,
      campaignContentTop,
      campaignVideo,
      campaignContentBottom,
    },
  },
}) => (
  <Layout>
    <Container>
      <CampaignBanner {...campaignBanner[0]} />
      <CampaignItems {...campaignItems[0]} />
      <CampaignContentTop {...campaignContentTop[0]} />
      <CampaignVideo {...campaignVideo[0]} />
      <CampaignContentBottom {...campaignContentBottom[0]} />
    </Container>
  </Layout>
);

export const query = graphql`
  query ($lang: String, $path: [String]) {
    campaignPage(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      pageName
      campaignBanner {
        ...CampaignBannerFragment
      }
      campaignItems {
        ...CampaignItemsFragment
      }
      campaignContentTop {
        ...CampaignContentTopFragment
      }
      campaignVideo {
        ...CampaignVideoFragment
      }
      campaignContentBottom {
        ...CampaignContentBottomFragment
      }
    }
  }
`;

export default CampaignPage;
